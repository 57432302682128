$primary: #1e6add; /* MAIN COLOR */
$secondary: #007999; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

.flash {
	display:none;
}

body {
	background: url(../img/bg2.png) repeat;
	// background: url(../img/bg.jpg) no-repeat left;
	font-size: 19px;
}

nav {
	z-index: 10;
}

.navbar .navbar-nav {
	

    > li > a {
		text-align: center;
		height: 80px;
		display: flex;
		align-items: center;

		@media (max-width: 767px) {
		    height: 45px;
		    display: block;
		    text-align: center;
		}


	    &:hover, &:focus {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }
	}
}


.navbar-right {
	margin-top: 0px;
	z-index: 1;
}


.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 767px) {
		margin-top: 18px;
	}
}



.navbar .navbar-nav {
  display: inline-block;
  float: none;
  vertical-align: top;
}

.navbar .navbar-collapse {
  text-align: center;
}


.navigation-clean-search .action-button, .navigation-clean-button .action-button:active {
  background: $primary;
  border-radius:20px;
  color:#fff !important;
  box-shadow:none;
  border:none;
  text-shadow:none;
  padding:10px 22px;
  transition:background-color 0.25s;
  margin-top: 1.5em;
	@media (max-width: 767px) {
		margin-top: 0px;
	}
}

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.75em 2em;
	font-size: 0.8em;
	font-weight: 100;
	letter-spacing: 1px;
	text-transform: uppercase;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}


@mixin btn2 {
	@include btn1;
  border:2px solid rgba(255,255,255,0.7);
  // border-radius:6px;
  color: $wht;
  background:transparent;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.btn-default {
	@include btn1;
}


 .modal-dialog {
	max-width: 314px;
	text-align: center;
	margin: 6em auto;
	z-index: 1000;


	hr {
		border: none;
		border-bottom: 2px dashed $primary;
	}
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		padding: 0em 0em 1em;
		
		h2 {
			text-align:center;
			font-size: 0.8em;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}


.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}


footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}


select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}







.logo {
	max-width: 250px;
	@media (max-width: 991px) {
		max-width: 175px;
		padding: 0.5em;
	}
}


/** HIGHLIGHT SECTIONS **/
.highlight-blue {
  color: $wht;
  background-color: $primary;
  box-shadow: 0px 0px 30px darken(rgba($primary,0.8),25%);
}

.highlight-blue p {
  color: $wht;
  font-weight: normal;
  letter-spacing: 1px;
  font-size: 22px;
  line-height:1.75;
  @media (max-width: 767px) {
  	font-size: 18px;
  }
}

.highlight-blue h2 {
  font-weight:normal;
  margin-bottom:25px;
  line-height:1.5;
  padding-top:0;
  margin-top:0;
  color:inherit;
}

.highlight-blue .intro {
  font-size:16px;
  // max-width:500px;
  margin:0 auto 25px;
}

.highlight-blue .buttons {
  text-align:center;
}

.highlight-blue .buttons .btn {
@include btn2;
}

.highlight-blue .buttons .btn:hover {
  opacity:1;
}

.highlight-blue .buttons .btn:active {
  transform:translateY(1px);
}

.highlight-blue .buttons .btn-primary, .highlight-blue .buttons .btn-primary:active {
	@include btn2;
  transition:background-color 0.25s;
}












/** CARDS **/
.card {
  box-shadow:0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  margin-top:10px;
  box-sizing:border-box;
  border-radius:2px;
  background-clip:padding-box;
  p {
  	color: $blk;
  }
		span.card-title {
  			color: $blk;
  			font-size:24px;
  			font-weight: bold;
  			text-transform:uppercase;
			border-bottom: 4px dashed $primary;
			text-align: center;
			width: 100%;
			display: block;
		}

	.card-image {
		position:relative;
		overflow:hidden;


		img {
 			border-radius:2px 2px 0 0;
  			background-clip:padding-box;
  			position:relative;
  			z-index:-1;
		}
	}

	.card-image1 {
		background: url(../img/card1.jpg) no-repeat center;
		background-size: cover;
		padding: 10em 0em 0em;
	}

	.card-image2 {
		background: url(../img/card2.jpg) no-repeat center;
		background-size: cover;
		padding: 10em 0em 0em;
	}
	.card-image3 {
		background: url(../img/card3.jpg) no-repeat center;
		background-size: cover;
		padding: 10em 0em 0em;
	}
}




.card .card-image img {
  border-radius:2px 2px 0 0;
  background-clip:padding-box;
  position:relative;
  z-index:-1;
}



.card .card-image span.card-title {
  position:absolute;
  bottom:0;
  left:0;
  padding:16px;
  width: 100%;
}

.card .card-content {
  padding:16px;
  border-radius:0 0 2px 2px;
  background-clip:padding-box;
  box-sizing:border-box;
  background:#fff;
  color:#333;
}

.card .card-content p {
  margin:0;
}

.card .card-action {
  border-top:1px solid rgba(160, 160, 160, 0.2);
  padding:16px;
  background:#fff;
}

.card .card-action a {
  color:#ffab40;
  margin-right:16px;
  transition:color 0.3s ease;
  text-transform:uppercase;
}

.card .card-action a:hover {
  color:#ffd8a6;
  text-decoration:none;
}








/** CUSTOM CSS **/


.bgDark {
	background: $primary;

}

.skew1 {
	transform: skewY(-2deg);
	padding: 7em 0em;
	margin-top: 6px;
}

.unSkew1 {
	transform: skewY(2deg);
}


.skew2 {
	transform: skewY(2deg);
	padding: 7em 0em;
	margin-top: 6px;
}


.unSkew2 {
	transform: skewY(-2deg);
}

.noPad {
	padding: 0em;
}


.jumbotron {
	margin-top: -4em;
	padding: 17em 0em 12em;
	background: linear-gradient(
		rgba(255,255,255,0.6),
		rgba(255,255,255,0.6)), 
		url(../img/banner.jpg) no-repeat center;
	background-size: cover;

	@media (max-width: 991px) {
		padding: 13em 0em 5em;
		background-position: -8em 0em;
	}

	h1 {
		font-size: 55px;
		@media (max-width: 991px) {
			font-size: 40px;
		}
	}
	
	p {
		font-weight: bold;
	}
}



.modal-open {
  overflow: auto; 
  padding-right: 0px !important;
}


@media (max-width: 991px) {
    footer {
    padding-left: 1em;
    padding-right: 1em;
  }

footer p, footer a {
    margin-bottom: 0.75em !important;
    font-size: 1em !important;
  }
}


.full {
	width: 100%;
}

#about {
	background: url(../img/bg3.jpg) no-repeat center;
	background-size: cover;

	@media (max-width: 767px) {
		background-position: -20em 0em;
	}

	@media (max-width: 500px) {
		background-position: -28em 0em;
	}	

	@media (max-width: 350px) {
		background-position: -38em 0em;
	}		
}

@media (max-width: 767px) {
	.navigation-clean-search .navbar-header {
		padding-top: 0px;
		padding-bottom: 0px;
	}
}